import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const items = [

  {
    quote: 'Showers are by far the biggest consumers of water in the home. Cutting just a minute off showering time would save British households £215 million on energy bills each year.',
    reference: 'The Guardian'
  },
  {
    quote: 'Reducing your shower from 6 minutes to 3 minutes could save approximately 541kg CO2 per year.',
    reference: 'Waterwise'
  },
  {
    quote: 'On average an eight minute shower uses around 65 litres of water, which is over three times the amount many people in the developing world rely on for a whole day.',
    reference: 'Tim Wainwright, WaterAid'
  },
];



class Quotes extends Component {
  render() {
    return (
      <Carousel
        renderIndicator={() => null}
        renderArrowPrev={() => null}
        renderArrowNext={() => null}
        autoPlay={true}
        interval={6000}
        infiniteLoop={true}
      >
        {items.map(item =>
          <div>
            <div className="start-page__quote">"{item.quote}"</div>
            <div className="start-page__reference">-{item.reference}</div>
          </div>
        )}
      </Carousel>
    );
  }
};
export default Quotes;