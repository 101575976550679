import React, { Component } from 'react';
import './App.css';
import queryString from "query-string";

import { Container, Row, Col } from 'reactstrap';

import ConnectSpotify from "./components/ConnectSpotify/ConnectSpotify";
import FindSongWithAccount from "./components/FindSong/FindSongWithAccount";
import FindSongWithoutAccount from "./components/FindSong/FindSongWithoutAccount"

import FullHeight from "react-full-height";

import Logo from "./assets/logo.svg"
import Animation from "./assets/animation.gif"

import { FaSpotify } from "react-icons/fa"

import Quotes from "./components/Quotes/Quotes"

import GA from './utils/GoogleAnalytics'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

class App extends Component {

  state = {
    accessToken: null,
    serverData: {
      user: null
    },
    connectedToSpotifyAccount: false,
    stateKnown: false,
    connecting: false
  }

  onClickConnect = () => {
    if (window.location.origin === "http://localhost:3000") {
      window.location = "http://localhost:8888/login"
    } else {
      window.location = "http://shower-song-backend.herokuapp.com/login"
    }
    this.setState({ stateKnown: true, connecting: true })
  }

  onClickContinue = () => {
    if (window.location.origin === "http://localhost:3000") {
      window.location = "http://localhost:8888/appauth"
    } else {
      window.location = "http://shower-song-backend.herokuapp.com/appauth"
    }
    this.setState({ stateKnown: false })
  }


  componentDidMount() {
    let parsed = queryString.parse(window.location.search)
    let accessToken = parsed.access_token;
    if (parsed.grant_type === "authorization_code") {
      fetch('https://api.spotify.com/v1/me', {
        headers: {
          "Authorization": "Bearer " + accessToken
        }
      }).then((response) => response.json()
        .then(data => {
          if (!data.error) {
            this.setState({ stateKnown: true, connectedToSpotifyAccount: true, accessToken, serverData: { ...this.state.serverData, user: data } })
          } else {
            this.onClickConnect()
          }
        }))
        .catch((error) => {
          this.onClickConnect();
        })
    } else if (parsed.grant_type === "client_credentials") {
      const url = new URL('https://api.spotify.com/v1/recommendations/available-genre-seeds');
      fetch(url.toString(), {
        headers: {
          "Authorization": "Bearer " + accessToken
        }
      }).then((response) => response.json()
        .then(data => {
          if (!data.error) {
            this.setState({ accessToken, stateKnown: true })
          } else {
            this.onClickContinue();
          }
        }))
        .catch((error) => {
          this.onClickConnect();
        })
    } else {
      this.setState({ stateKnown: true })
    }
  }

  render() {

    return (
      <BrowserRouter>
        {GA.init() && <GA.RouteTracker />}
        <Switch>
          <Route path='/'>
            <div className="App">
              <div
                style={{
                  position: "absolute",
                  margin: 8,
                  top: 0,
                  right: 0,
                  zIndex: 9999999,
                  fontSize: 12,
                  opacity: 0.5,
                }}
              >
                Designed and built by{" "}
                <a
                  style={{ color: "white", textDecoration: "underline" }}
                  href="https://bengrav.es/"
                  target="_blank"
                >
                  Ben Graves
          </a>
              </div>
              {this.state.stateKnown ?
                !this.state.connecting ?
                  this.state.accessToken ?
                    this.state.connectedToSpotifyAccount ?
                      <FindSongWithAccount
                        reconnect={() => this.onClickConnect()}
                        disconnect={() => this.onClickContinue()}
                        data={this.state.serverData} accessToken={this.state.accessToken}
                        verify={(accessToken) => {
                          fetch('https://api.spotify.com/v1/me', {
                            headers: {
                              "Authorization": "Bearer " + accessToken
                            }
                          }).then((response) => response.json()
                            .then(data => {
                              if (!data.error) {
                                this.setState({ stateKnown: true, connectedToSpotifyAccount: true, serverData: { ...this.state.serverData, user: data } })
                              } else {
                                this.onClickConnect()
                              }
                            }))
                            .catch((error) => {
                              this.onClickConnect();
                            })
                        }}
                      />
                      : <FindSongWithoutAccount
                        reconnect={() => this.onClickContinue()}
                        accessToken={this.state.accessToken}
                        verify={(accessToken) => {
                          const url = new URL('https://api.spotify.com/v1/recommendations/available-genre-seeds');
                          fetch(url.toString(), {
                            headers: {
                              "Authorization": "Bearer " + accessToken
                            }
                          }).then((response) => response.json()
                            .then(data => {
                              if (!data.error) {
                                this.setState({ accessToken, stateKnown: true })
                              } else {
                                this.onClickContinue();
                              }
                            }))
                            .catch((error) => {
                              this.onClickConnect();
                            })
                        }
                        }
                      />
                    :
                    <FullHeight className="start-page__background">
                      <Container className="bottom-content">
                        <Row>
                          <Col sm="12" md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                            <img className="start-page__logo" src={Logo} alt="Shower Tune" />
                            <div className="start-page__title">Shower Tune</div>
                            <Quotes />
                            <div className="start-page__tagline">
                              Find your perfect tune for a shorter shower
                </div>
                            <button onClick={() => this.onClickConnect()} className="start-page__connect-button">
                              <FaSpotify className="button-icon" /> Connect to Spotify
                </button>
                            <div className="start-page__or">
                              or
                </div>
                            <button onClick={() => this.onClickContinue()} className="start-page__continue-without-button">
                              Continue without Spotify
                </button>
                          </Col>
                        </Row>
                      </Container>
                    </FullHeight>
                  :
                  <FullHeight className="start-page__background">
                    <Container className="central-content">
                      <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                          <img src={Animation} width={"50%"} alt="loading animation " />
                          <div>
                            Connecting to Spotify...
                  </div>
                        </Col>
                      </Row>
                    </Container>
                  </FullHeight>
                :
                <FullHeight>
                  <Container className="central-content">
                    <Row>
                      <Col sm="12" md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                        <img src={Animation} width={"50%"} alt="loading animation " />
                        <div>
                          Loading...
                </div>
                      </Col>
                    </Row>
                  </Container>
                </FullHeight>
              }
            </div >
          </Route>
        </Switch>
      </BrowserRouter>

    );
  }
}


export default App;
