import React, { Component } from 'react';
import { Button, Input, Form, FormGroup, Label } from 'reactstrap';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

import "./FindSong.css";

import { FiMoreHorizontal } from "react-icons/fi"

import {
    FacebookShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    WeiboIcon,
} from "react-share";

class Share extends Component {
    msToMins = (ms) => {
        return ms / 60000;
    }

    isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }

    state = {
        more: false
    }

    render() {
        let duration = Math.round(this.msToMins(this.props.song.duration_ms) * 2) / 2;
        let litresSaved = 65 - (65 /8)*duration
        const shareUrl = "https://www.showertune.app/";
        
        let title = 'Today I used Shower Tune to shorten my shower to ' + duration + ' minutes, saving ' + litresSaved +' litres compared to the average shower! I listened to ' + this.props.song.artists[0].name + " by " + this.props.song.name + " ";

        if (litresSaved === 0) {
            title = 'Today I used Shower Tune to shorten my shower to ' + duration + ' minutes! I listened to ' + this.props.song.artists[0].name + " by " + this.props.song.name + " "; 
        }

        return (
            <div className="share__container">
                <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="share__button"
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>

                {this.isMobile() ?
                    <span
                        onClick={() => {
                            var win = window.open("fb-messenger://share/?link= " + shareUrl + " " + title)
                            win.focus();
                        }}
                        className="share__button"
                    >
                        <FacebookMessengerIcon size={32} round />
                    </span>

                    :

                    <FacebookMessengerShareButton
                        url={shareUrl}
                        className="share__button"
                    >
                        <FacebookMessengerIcon size={32} round />
                    </FacebookMessengerShareButton>
                }

                <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    hashtags={["TuneYourShower"]}
                    className="share__button"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>



                <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                    className="share__button"
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                {this.state.more ? null : <FiMoreHorizontal className={"more-button"} onClick={() => this.setState({more: true})}/>}

                {this.state.more ?
                    <span>
                        <LinkedinShareButton url={shareUrl} className="share__button">
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>

                        <PinterestShareButton
                            url={String(window.location)}
                            media={this.props.song.album.images[0]}
                            className="share__button"
                        >
                            <PinterestIcon size={32} round />
                        </PinterestShareButton>

                        <RedditShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="share__button"
                        >
                            <RedditIcon size={32} round />
                        </RedditShareButton>

                        <TumblrShareButton
                            url={shareUrl}
                            title={title}
                            className="share__button"
                        >
                            <TumblrIcon size={32} round />
                        </TumblrShareButton>

                        <EmailShareButton
                            url={shareUrl}
                            subject={title}
                            body="body"
                            className="share__button"
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </span>
                    : null}
            </div>
        );
    }
}

export default Share;
